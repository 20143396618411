import { ApolloClient } from "@apollo/client";
import { capitalize } from "radash";
import {
  DoctorFindAddressDocument,
  DoctorFindAddressQuery,
  DoctorFindAddressQueryVariables,
} from "lib/graphql/megaSchema";

type RequiredButNullable<T> = {
  [K in keyof T]-?: T[K];
};

export const fetchPlaces = async (
  client: ApolloClient<object>,
  query: string,
) => {
  const { data } = await client.query<
    DoctorFindAddressQuery,
    DoctorFindAddressQueryVariables
  >({
    query: DoctorFindAddressDocument,
    variables: { query },
  });

  return data.doctorFindAddress.map((place) => ({
    value: place as RequiredButNullable<
      DoctorFindAddressQuery["doctorFindAddress"][0]
    >,
    label: `${place.city}, ${place.street} ${place.houseNumber}, ${
      place.zipCode
    }, ${capitalize(place.state ?? "")}`,
  }));
};
