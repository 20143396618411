import { DoctorMeQuery } from "lib/graphql/megaSchema";
import { isPastVisit } from "lib/tools/isPastVisit";
import { procedureTitleMap } from "lib/tools/procedureTitleMap";
import {
  Visit,
  VisitsItem,
} from "views/Visits/PerDay/components/VisitList/types";

export const getSelectedDoctorFromCurrentUser = (
  currentUser: DoctorMeQuery["doctorMe"],
): Pick<DoctorMeQuery["doctorMe"], "id" | "firstName" | "lastName"> => {
  const { id, firstName, lastName } = currentUser;
  return { id, firstName, lastName };
};

export const getVisitTypeLabel = (
  procedures: VisitsItem["procedures"],
  laboratoryExaminations: VisitsItem["laboratoryExaminations"],
  visitType: VisitsItem["type"],
) => {
  if (
    visitType !== "PROCEDURE" ||
    (procedures.length === 0 && laboratoryExaminations.length === 0)
  ) {
    return "–";
  }

  if (procedures.length === 0) {
    return "Badanie lab";
  }

  const procedureElement = procedures[0];

  if (procedures.length === 1) {
    return procedureTitleMap[procedureElement.type];
  }

  if (procedures.length > 1) {
    return `${procedureTitleMap[procedureElement.type]} (+${procedures.length - 1})`;
  }

  return "–";
};

export const getProcedureVisitAdditionalInfo = (
  procedures: VisitsItem["procedures"],
  laboratoryExaminations: VisitsItem["laboratoryExaminations"],
) => {
  const procedureElement = procedures[0];
  const laboratoryExaminationElement = laboratoryExaminations[0];
  const fallbackCopy = "";

  if (laboratoryExaminations.length === 1) {
    return laboratoryExaminationElement.assignment.name;
  }

  if (laboratoryExaminations.length > 1) {
    return `${laboratoryExaminationElement.assignment.name} + ${
      laboratoryExaminations.length - 1
    } innych badań laboratoryjnych`;
  }

  if (!procedureElement) {
    return fallbackCopy;
  }

  if (procedureElement.usg) {
    return procedureElement.usg.referral?.procedureName;
  }

  if (procedureElement.ekg) {
    return fallbackCopy;
  }

  if (procedureElement.education) {
    if (!procedureElement.education.comment) {
      return fallbackCopy;
    }

    return procedureElement.education.comment;
  }

  if (procedureElement.chukEducation) {
    if (!procedureElement.chukEducation.comment) {
      return fallbackCopy;
    }

    return procedureElement.chukEducation.comment;
  }

  if (procedureElement.chukMeasurements) {
    if (!procedureElement.chukMeasurements.comment) {
      return fallbackCopy;
    }

    return procedureElement.chukMeasurements.comment;
  }

  return fallbackCopy;
};

export const getIsVisitCompletedOrOutdated = (visit: Visit) =>
  Boolean(
    visit.prescriptionRequestId
      ? visit.snapshotBy
      : visit.snapshotBy || isPastVisit(visit),
  );
