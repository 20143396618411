import { BinIcon, Button, Tag } from "@jutro/ui";
import dayjs from "dayjs";
import { ComponentProps, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "components/new/ConfirmDialog";
import { StaffAvatar } from "components/new/StaffAvatar";
import {
  DoctorDraftVisitsDocument,
  DoctorDraftVisitsQuery,
  DoctorMSVisitType,
  useDoctorDeleteVisitMutation,
  useDoctorGetDoctorFullNameQuery,
} from "lib/graphql/megaSchema";
import { getDisplayDoctor } from "lib/tools/getDisplayDoctor";
import { ItemOf, Maybe } from "lib/types";
import { VisitStatusTag } from "views/Visits/PerDay/components/VisitList/VisitTable/VisitStatusTag";

type ExtendedVisitType = DoctorMSVisitType | "PRESCRIPTION";

const returnStatusInfo = (
  visit: Exclude<
    ItemOf<DoctorDraftVisitsQuery["doctorDraftVisits"]>,
    null | undefined
  >,
) => {
  const {
    status,
    plannedStart,
    invitationSentAt,
    type,
    doctorId,
    invitationSentBy,
    createdAt,
  } = visit;
  if (status === "DRAFT" && !invitationSentAt && type !== "PROCEDURE") {
    return {
      date: plannedStart?.iso,
      personId: doctorId,
    };
  }

  if (!plannedStart) {
    return {
      date: invitationSentAt?.iso ?? createdAt.iso,
      personId: invitationSentBy?.id,
    };
  }

  return {
    date: plannedStart.iso,
    personId: doctorId,
  };
};

type StatusInfoProps = {
  visit: Exclude<
    ItemOf<DoctorDraftVisitsQuery["doctorDraftVisits"]>,
    null | undefined
  >;
};

const StatusInfo = ({ visit }: StatusInfoProps) => {
  const { date, personId } = returnStatusInfo(visit);
  const { data } = useDoctorGetDoctorFullNameQuery({
    variables: {
      id: personId ?? "",
    },
    skip: !personId,
  });

  return (
    <div className="flex items-center gap-2">
      <span className="text-xs font-semibold">
        {dayjs(date).format("YYYY-MM-DD HH:mm")}
      </span>

      <div className="flex">
        {personId ? (
          <StaffAvatar
            userId={personId}
            duration={visit.duration ?? 10}
            modal
            size="sm"
          />
        ) : null}
        <div className="font-paragraph-2">
          {getDisplayDoctor(
            data?.doctorDoctor?.firstName,
            data?.doctorDoctor?.lastName,
            "Brak lekarza",
          )}
        </div>
      </div>
    </div>
  );
};

const visitTagMap: Record<
  ExtendedVisitType,
  { color: ComponentProps<typeof Tag>["color"]; text: string }
> = {
  STANDARD: { color: "blue", text: "Wizyta osobista" },
  VIRTUAL: { color: "green", text: "Wizyta telemedyczna" },
  PROCEDURE: { color: "rose", text: "Wizyta zabiegowa" },
  PRESCRIPTION: { color: "orange", text: "Wizyta receptowa" },
};

export const VisitTagTypeHelper = ({
  type,
}: {
  type: Maybe<ExtendedVisitType>;
}) => {
  if (!type) {
    return (
      <Tag text="Kopia robocza" color="black-or-white" variant="inverted" />
    );
  }

  const { color, text } = visitTagMap[type];

  return <Tag color={color} text={text} />;
};

export const DraftElement = ({
  visit,
  variant = "normal",
}: {
  visit: ItemOf<DoctorDraftVisitsQuery["doctorDraftVisits"]>;
  variant?: "history" | "normal";
}) => {
  const [deleteVisitModalOpen, setDeleteVisitModalOpen] = useState(false);

  const navigate = useNavigate();

  const [deleteVisitMutation] = useDoctorDeleteVisitMutation({
    variables: { id: visit.id },
    refetchQueries: [DoctorDraftVisitsDocument],
  });

  if (!visit) {
    return null;
  }

  const { type, id } = visit;

  return (
    <div
      className="font-geologica flex cursor-pointer items-center justify-between rounded-lg border border-jutro-new-warm-gray-100 p-2"
      onClick={
        variant === "history" ? () => {} : () => navigate("/visits/" + id)
      }
    >
      <div>
        <div className="flex flex-col gap-2">
          {variant === "normal" && (
            <div className="mb-1 flex gap-2">
              <VisitTagTypeHelper type={type} />
              {type && <VisitStatusTag visitElement={visit} />}
            </div>
          )}
          <StatusInfo visit={visit} />
        </div>
      </div>
      <div>
        {!type && (
          <div className="flex flex-col items-center justify-center gap-2 p-3">
            <Button
              full={false}
              icon={<BinIcon />}
              label="Usuń"
              tooltipPosition="top"
              size="condensed"
              variant="negativeGhost"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteVisitModalOpen(true);
              }}
            />
          </div>
        )}
      </div>

      <ConfirmDialog
        title="Czy chcesz usunąć kopię roboczą?"
        text="Tej operacji nie będzie można cofnąć."
        confirmText="Usuń kopię roboczą"
        open={deleteVisitModalOpen}
        setOpen={setDeleteVisitModalOpen}
        onConfirm={async () => {
          await deleteVisitMutation();
        }}
      />
    </div>
  );
};
