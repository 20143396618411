import { useOnClickOutside } from "@jutro/hooks";
import {
  BellIcon,
  CalendarClockIcon,
  CardPatientFolderIcon,
  ChatBubblesIcon,
  DevToolIcon,
  GlobeInformationIcon,
  Tooltip,
  UsersClockIcon,
  WindowLinkIcon,
} from "@jutro/ui";
import { useAtom } from "jotai";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";
import { DoctorProfileCertificate } from "components/DoctorProfileCertificate";
import { DoctorProfileSignature } from "components/DoctorProfileSignature";
import { NewsPostList } from "components/News/Post/List";
import { Avatar, Loader } from "components/new";
import { ChangePasswordSideSheet } from "components/new/ChangePassowordSideSheet";
import { PopupMenu } from "components/new/PopupMenu";
import { RaportDialog } from "components/new/RaportDialog";
import { JMLogo } from "components/new/RouteWrapper/components/JMLogo";
import { examinationDrawerPositionAtom } from "lib/atoms";
import { currentUserAtom } from "lib/atoms/auth";
import { useDoctorGetMySeenPostsQuery } from "lib/graphql/megaSchema";
import { useLogout } from "lib/hooks/auth";
import { usePosts } from "lib/hooks/news";
import { isProductionEnvironment } from "lib/tools";
import { TileColumn } from "lib/tools/createWorkspace/types";
import {
  getCurrentWorkspace,
  useTileSystem,
} from "lib/tools/createWorkspace/useTileSystem";

const outerLinks = [
  {
    id: "zus-pue-outer-link",
    label: "ZUS PUE",
    link: "https://www.zus.pl/ezus/logowanie",
  },
  {
    id: "gabinet-gov-outer-link",
    label: "Gabinet.gov.pl",
    link: "https://gabinet.gov.pl/",
  },
  {
    id: "ktomalek-outer-link",
    label: "KtoMaLek.pl",
    link: "https://ktomalek.pl/",
  },
  {
    id: "terminyleczenia-outer-link",
    label: "TerminyLeczenia.nfz.gov.pl",
    link: "https://terminyleczenia.nfz.gov.pl/",
  },
  {
    id: "bartoszmowi-outer-link",
    label: "BartoszMówi.pl",
    link: "https://www.bartoszmowi.pl/",
  },
  {
    id: "medycynapraktyczna-outer-link",
    label: "MedycynaPraktyczna.pl",
    link: "https://www.mp.pl/",
  },
  {
    id: "diagnostyka-wyniki-outer-link",
    label: "Diagnostyka wyniki",
    link: "https://cswl.diag.pl/",
  },
  {
    id: "rentgen-spoldzielnia-outer-link",
    label: "Rentgen Spółdzielnia",
    link: "https://www.rentgen.pl/",
  },
  {
    id: "zlecenie-zaopatrzenia-outer-link",
    label: "Zlecenie zaopatrzenia",
    link: "https://ezwm.nfz.gov.pl/",
  },
];

export const Menu = () => {
  const [doctorProfileCertificateOpen, setDoctorProfileCertificateOpen] =
    useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [doctorProfileSignatureOpen, setDoctorProfileSignatureOpen] =
    useState(false);
  const [newsOpen, setNewsOpen] = useState(false);
  const [outerLinksOpen, setOuterLinksOpen] = useState(false);
  const { data: posts } = usePosts();
  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
  const [showDevDropdown, setShowDevDropdown] = useState(false);
  const [position] = useAtom(examinationDrawerPositionAtom);

  const [raportDialogOpen, setRaportDialogOpen] = useState(false);

  const logout = useLogout();
  const [currentUser] = useAtom(currentUserAtom);

  const navigate = useNavigate();

  const staffMap: { [key: string]: string } = {
    DOCTOR: "Lekarz",
    NURSE: "Pielęgniarka",
    ASSISTANT: "Asystent",
    STAFF: "Administrator",
  };

  const postIds = useMemo(() => (posts ? posts.map((p) => p.id) : []), [posts]);

  const { data: meData } = useDoctorGetMySeenPostsQuery();

  const showNewNotificationsDot = useMemo(() => {
    if (!meData || !posts) return;
    const mySeenPosts = meData.doctorMe.seenPosts;
    return postIds.length > 0
      ? postIds.some((id) => (id ? !mySeenPosts.includes(id) : false))
      : false;
  }, [meData, posts]);

  const notificationsCount = useMemo(() => {
    if (!meData || !posts) return;
    const mySeenPosts = [...new Set(meData.doctorMe.seenPosts)];
    const mySeenIds = mySeenPosts.filter((p) => postIds.includes(p)).length;
    return postIds.length - mySeenIds;
  }, [posts, meData]);

  const returnDiagRaportTile = () => {
    if (currentUser?.genre !== "NURSE") {
      return null;
    }

    return {
      id: "diag-raport-popup-menu",
      label: "Raport diagnostyka",
      action: () => setRaportDialogOpen(true),
    };
  };

  const { addTile } = useTileSystem();

  const popupDevMenuItems = [
    {
      id: "diag-simulator",
      label: "Diag simulator",
      action: () => navigate("/diag-simulator"),
    },
    {
      id: "slot-creator",
      label: "Slots creator",
      action: () => navigate("/slots-creator"),
    },
    {
      id: "visits-confirmation",
      label: "Visits confirmation",
      action: () => navigate("/visits-confirmation"),
    },
    {
      id: "mdx-playground",
      label: "Mdx playground",
      action: () => navigate("/mdx-playground"),
    },
    {
      id: "prescription-requests",
      label: "Prescription requests",
      action: () => navigate("/prescription-request"),
    },
  ];

  const popupAvatarMenuItems = [
    returnDiagRaportTile(),
    ...(currentUser?.genre !== "STAFF"
      ? [
          {
            id: "certificate-zus-popup-menu",
            label: "Certyfikat ZUS",
            action: () => setDoctorProfileCertificateOpen(true),
          },
          {
            id: "signature-popup-menu",
            label: "Podpis",
            action: () => setDoctorProfileSignatureOpen(true),
          },
          {
            id: "template-settings-popup-menu",
            label: "Szablony",
            action: () => {
              try {
                getCurrentWorkspace();

                addTile(
                  {
                    id: "snippet-settings",
                    name: "snippet-settings",
                    data: {},
                  },
                  TileColumn.Left,
                );
              } catch {
                navigate("/settings");
              }
            },
          },
        ]
      : []),
    {
      id: "change-password-popup-menu",
      label: "Zmień hasło",
      action: () => setChangePasswordOpen(true),
    },
    {
      id: "logout-popup-menu",
      label: "Wyloguj",
      action: () => logout(),
    },
  ];

  if (!currentUser || !currentUser.genre) return <Loader />;

  return (
    <>
      <div
        className={`${
          position === "left" ? "" : "z-10"
        } flex h-full w-14 flex-col items-center justify-between rounded-lg bg-white p-4`}
      >
        <div className="flex flex-col gap-2">
          <Link
            id="home-menu"
            to={currentUser.genre === "DOCTOR" ? "/visits/per-day" : "/"}
          >
            <JMLogo />
          </Link>

          {import.meta.env.REACT_APP_VERCEL_TARGET_ENV === "preview" && (
            <div className="font-caption text-jutro-new-blue-800">STAG</div>
          )}

          {import.meta.env.MODE === "development" && (
            <div className="font-caption text-jutro-new-blue-800">DEV</div>
          )}

          {import.meta.env.REACT_APP_VERCEL_TARGET_ENV === "beta" && (
            <div className="font-caption text-jutro-new-blue-800">BETA</div>
          )}
        </div>

        <section className="z-1 flex flex-col items-center gap-4">
          <div className="-mr-0.5 flex flex-col gap-4">
            {!isProductionEnvironment() && (
              <MenuIconGroup
                className="relative flex flex-col justify-center"
                setOpen={setShowDevDropdown}
                id="dev-menu"
              >
                <Tooltip
                  position="right"
                  trigger={
                    <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                      <DevToolIcon />
                    </div>
                  }
                  content="Narzędzia dla developerów"
                  disabled={showDevDropdown}
                />
                <PopupMenu
                  position="top-right"
                  items={popupDevMenuItems}
                  active={showDevDropdown}
                />
              </MenuIconGroup>
            )}

            {!isProductionEnvironment() && (
              <Tooltip
                position="right"
                content="Planer pracy"
                triggerAsChild={false}
                trigger={
                  <MenuLink to="/staff-scheduler">
                    <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                      <UsersClockIcon />
                    </div>
                  </MenuLink>
                }
              />
            )}

            <Tooltip
              position="right"
              content="Baza wiedzy Jutro Medical"
              trigger={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://jutromedicalsupport.zendesk.com/hc/pl"
                >
                  <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                    <GlobeInformationIcon />
                  </div>
                </a>
              }
            />

            <Tooltip
              position="right"
              content="Harmonogram dnia"
              triggerAsChild={false}
              trigger={
                <MenuLink to="/schedule">
                  <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                    <CalendarClockIcon />
                  </div>
                </MenuLink>
              }
            />

            <Tooltip
              position="right"
              content="Baza pacjentow"
              triggerAsChild={false}
              trigger={
                <MenuLink
                  to="/patients"
                  rule={(location: Location) =>
                    location.pathname.slice(0, 9) === "/patients"
                  }
                >
                  <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                    <CardPatientFolderIcon />
                  </div>
                </MenuLink>
              }
            />

            {currentUser.genre !== "STAFF" && (
              <>
                <Tooltip
                  position="right"
                  content="Wizyty"
                  triggerAsChild={false}
                  trigger={
                    <MenuLink
                      to="/visits"
                      rule={(location: Location) =>
                        location.pathname.slice(0, 7) === "/visits"
                      }
                    >
                      <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                        <ChatBubblesIcon />
                      </div>
                    </MenuLink>
                  }
                />

                <Tooltip
                  position="right"
                  disabled={outerLinksOpen}
                  content="Linki zewnętrzne"
                  triggerAsChild={false}
                  trigger={
                    <MenuIconGroup
                      setOpen={setOuterLinksOpen}
                      className="relative"
                    >
                      <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                        <WindowLinkIcon />
                      </div>
                      <PopupMenu
                        items={outerLinks}
                        active={outerLinksOpen}
                        position="top-right"
                      />
                    </MenuIconGroup>
                  }
                />
              </>
            )}

            <Tooltip
              position="right"
              content="Aktualności"
              trigger={
                <div onClick={() => setNewsOpen(true)} className="flex">
                  <div className="grid h-10 w-10 cursor-pointer place-items-center rounded-lg p-2 hover:bg-jutro-new-warm-gray-100">
                    <BellIcon />
                  </div>
                  {showNewNotificationsDot && (
                    <div
                      style={{ backgroundColor: "#FF375F" }}
                      className="font-mono -ml-4 -mt-0.5 grid h-4 w-4 place-items-center rounded-full text-[11px] font-bold text-white"
                    >
                      <div
                        style={{ marginTop: -5, marginRight: 0 }}
                        className="h-3"
                      >
                        {notificationsCount}
                      </div>
                    </div>
                  )}
                </div>
              }
            />
          </div>

          <MenuIconGroup
            className="relative flex flex-col justify-center"
            setOpen={setShowAvatarDropdown}
            id="user-menu"
          >
            <button className="font-paragraph-2 flex max-w-xs items-center rounded-full bg-jutro-new-warm-gray-800 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              <span className="sr-only">Open user menu</span>
              <Avatar userId={currentUser.id} />
            </button>

            <PopupMenu
              position="top-right"
              items={popupAvatarMenuItems}
              active={showAvatarDropdown}
              header={
                <div className="mb-2 px-4">
                  <div className="font-medium">
                    {currentUser.firstName} {currentUser.lastName}
                  </div>

                  <div className="font-paragraph-2 -mt-1">
                    <span className="text-jutro-new-warm-gray-400">
                      konto:{" "}
                    </span>
                    <span className="text-jutro-new-warm-gray-500">
                      {staffMap[currentUser.genre]}
                    </span>
                  </div>
                </div>
              }
            />
          </MenuIconGroup>
        </section>
      </div>

      <DoctorProfileSignature
        open={doctorProfileSignatureOpen}
        setClose={() => {
          setDoctorProfileSignatureOpen(false);
        }}
      />

      <DoctorProfileCertificate
        open={doctorProfileCertificateOpen}
        setClose={() => {
          setDoctorProfileCertificateOpen(false);
        }}
      />

      <ChangePasswordSideSheet
        open={changePasswordOpen}
        setClose={() => setChangePasswordOpen(false)}
      />

      <NewsPostList
        open={newsOpen}
        onClose={() => {
          setNewsOpen(false);
        }}
      />
      <RaportDialog open={raportDialogOpen} setOpen={setRaportDialogOpen} />
    </>
  );
};

type MenuIconGroupType = PropsWithChildren<{
  setOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
  id?: string;
}>;

const MenuIconGroup = ({
  children,
  setOpen,
  className,
  id,
}: MenuIconGroupType) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div
      id={id}
      ref={ref}
      className={className}
      onClick={() => setOpen((prev) => !prev)}
    >
      {children}
    </div>
  );
};

type MenuLinkProps = PropsWithChildren<{
  to: string;
  rule?: (location: Location) => boolean;
}>;

export const MenuLink = ({ children, to, rule }: MenuLinkProps) => {
  const location = useLocation();

  return (
    <Link to={to}>
      <div
        className={
          (rule ? rule(location) : location.pathname === to)
            ? `rounded-lg bg-jutro-blue-transparentDefault text-jutro-new-blue-800`
            : "rounded-lg text-jutro-new-warm-gray-800 hover:bg-jutro-new-warm-gray-100"
        }
      >
        {children}
      </div>
    </Link>
  );
};
