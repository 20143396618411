import { useFlag } from "lib/hooks/flags";
import { VisitsItem } from "views/Visits/PerDay/components/VisitList/types";
import { getProcedureVisitAdditionalInfo } from "views/Visits/PerDay/tools";

type Props = {
  visit: VisitsItem;
};

export const AdditionalVisitInfo = ({ visit }: Props) => {
  const isIpomEnabled = useFlag("ipom");

  const isQualifiedForIPOM =
    Boolean(visit.determineIpomEligibility) && isIpomEnabled;
  const patientHasIPOM = Boolean(visit.patient?.ipomCode) && isIpomEnabled;

  const procedureVisitAdditionalInfo = getProcedureVisitAdditionalInfo(
    visit.procedures,
    visit.laboratoryExaminations,
  );

  if (procedureVisitAdditionalInfo) {
    return procedureVisitAdditionalInfo;
  }

  if (isQualifiedForIPOM) {
    return "Rozpoznanie kwalifikujące do OK";
  }

  if (patientHasIPOM) {
    return "Pacjent w opiece koordynowanej";
  }

  return "–";
};
