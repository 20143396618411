export const JMLogo = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 21.8542C0.593206 21.7382 1.0116 21.5802 1.25568 21.3805C1.49948 21.1804 1.62155 20.8936 1.62155 20.5197V9.97915L4.60753 8.62527H4.90421V19.9783C4.90421 20.4037 4.80836 20.7935 4.61744 21.1483C4.42617 21.5028 4.15579 21.8026 3.80666 22.0477C3.45718 22.2925 3.03202 22.4828 2.53117 22.6182C2.03004 22.7536 1.47632 22.8213 0.870068 22.8213H0V21.8542ZM3.3815 3.5C3.93522 3.5 4.39003 3.64837 4.746 3.94483C5.1019 4.24165 5.27991 4.62177 5.27991 5.08599C5.27991 6.05298 4.65357 6.53651 3.40131 6.53651C2.83426 6.53651 2.37296 6.39141 2.017 6.10137C1.6611 5.81125 1.48309 5.42778 1.48309 4.95059C1.48309 4.48637 1.6512 4.12862 1.98735 3.8772C2.32351 3.62571 2.78822 3.5 3.3815 3.5ZM8.16677 9.86306L11.311 8.52856V10.2693C11.9305 9.63772 12.5073 9.18619 13.0413 8.91539C13.5752 8.64472 14.1585 8.50932 14.7914 8.50932C15.4767 8.50932 16.0304 8.64472 16.4525 8.91539C16.8742 9.18619 17.2367 9.63772 17.54 10.2693C18.2254 9.62475 18.8517 9.17023 19.4187 8.9057C19.9854 8.64159 20.5984 8.50932 21.2578 8.50932C21.6533 8.50932 22.0157 8.57702 22.3454 8.71228C22.6748 8.84769 22.9584 9.03483 23.1957 9.27328C23.433 9.51201 23.6175 9.79878 23.7494 10.1339C23.881 10.4694 23.9471 10.8301 23.9471 11.2169V19.0499H20.9018V12.0872C20.9018 11.5717 20.7699 11.1559 20.5064 10.8398C20.2425 10.524 19.9063 10.3659 19.4978 10.3659C19.2867 10.3659 19.1023 10.392 18.9441 10.4433C18.7859 10.5207 18.6308 10.6078 18.4794 10.7044C18.3277 10.8012 18.1927 10.9075 18.074 11.0235C17.8367 11.2556 17.7181 11.4233 17.7181 11.5264V19.0499H14.5342V11.5651C14.5342 11.5393 14.4947 11.4363 14.4157 11.2556C14.2441 10.9205 14.0662 10.685 13.8817 10.5498C13.697 10.4144 13.4532 10.3467 13.15 10.3467C12.7808 10.3467 12.4446 10.4563 12.1415 10.6755C11.9438 10.8301 11.7392 11.0429 11.5285 11.3137C11.3833 11.5201 11.311 11.6361 11.311 11.6618V19.0499H8.16677V9.86306Z"
      fill="#0A40A1"
    />
  </svg>
);
