import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { PerDayVisitsQuery } from "lib/graphql/megaSchema";
import { isPastVisit } from "lib/tools/isPastVisit";
import { Visit } from "../types";

export const prepareVisits = (
  visits: PerDayVisitsQuery["doctorGetVisitsPerDayForDoctor"],
  showOnlyTodoVisits = false,
  currentTime?: Dayjs,
) => {
  return visits
    .filter((visit) => {
      if (!showOnlyTodoVisits) return true;

      const isTodo =
        visit.status !== "SUCCESS" && !isPastVisit(visit, currentTime);

      return isTodo;
    })
    .map((visit) => {
      const visitHour = dayjs(visit.plannedStart!.iso).hour();

      return { ...visit, hour: visitHour };
    });
};

export const useVisits = (
  visits: PerDayVisitsQuery["doctorGetVisitsPerDayForDoctor"] | undefined,
  showOnlyTodoVisits: boolean,
): Visit[] => {
  return useMemo(() => {
    if (!visits) return [];

    return prepareVisits(visits, showOnlyTodoVisits);
  }, [visits, showOnlyTodoVisits]);
};
