import { useAtom } from "jotai";
import { Fragment, useState } from "react";
import { currentUserAtom } from "lib/atoms/auth";
import {
  DoctorMSUserGenre,
  DoctorNextPatientsDocument,
  DoctorPatientsDocument,
  DoctorVisitDocument,
  useDoctorUnpinAllPatientsMutation,
  useDoctorUnpinPatientMutation,
} from "lib/graphql/megaSchema";
import { useNextPatients } from "lib/hooks/useNextPatients";
import { Maybe } from "lib/types";
import { ActivePatientList } from "views/Visits/Active/components/PatientList";
import { VisitsTabs } from "views/Visits/components/VisitsTabs";

export const ActiveVisitsTab = () => {
  const [isUnpinning, setIsUnpinning] = useState<Maybe<string>>(null);
  const [currentUser] = useAtom(currentUserAtom);
  const [unpinPatientMutation] = useDoctorUnpinPatientMutation({
    refetchQueries: [
      DoctorVisitDocument,
      DoctorPatientsDocument,
      DoctorNextPatientsDocument,
    ],
    awaitRefetchQueries: true,
  });
  const { patients, refetchPatients } = useNextPatients();

  const [unpinAllPatientsMutation] = useDoctorUnpinAllPatientsMutation({
    refetchQueries: [
      DoctorVisitDocument,
      DoctorPatientsDocument,
      DoctorNextPatientsDocument,
    ],
    awaitRefetchQueries: true,
  });

  const enhancedUnpin = async (patientId: string | "all") => {
    if (isUnpinning) {
      return;
    }

    setIsUnpinning(patientId);

    if (patientId === "all") {
      await unpinAllPatientsMutation();
    } else {
      await unpinPatientMutation({ variables: { id: patientId } });
    }

    await refetchPatients();

    setIsUnpinning(null);
  };

  const patientTabTitleMap: Record<DoctorMSUserGenre, string> = {
    DOCTOR: `Moi pacjenci (${patients.mineAwaitingResponse}/${patients.mine.length})`,
    ASSISTANT: `Moi pacjenci (${patients.mineAwaitingResponse}/${patients.mine.length})`,
    get ["NURSE"]() {
      return this["ASSISTANT"];
    },
    STAFF: "",
    PATIENT: "",
    MODERATOR: "",
    CLINIC_MANAGER: "",
  };

  return (
    <Fragment>
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-row gap-2.5 pb-4">
          <h1 className="font-heading-1 pl-1 text-jutro-new-warm-gray-800">
            Wizyty
          </h1>

          <VisitsTabs />
        </div>
      </div>

      <div className="mb-6 flex flex-col gap-y-4 overflow-auto">
        <ActivePatientList
          title={
            currentUser?.genre ? patientTabTitleMap[currentUser.genre] : ""
          }
          patientList={patients.mine}
          unpin={enhancedUnpin}
          isFunctionButtonsEnabled
        />

        <ActivePatientList
          title={`Lista oczekujących (${patients.others.length})`}
          patientList={patients.others}
        />
      </div>
    </Fragment>
  );
};
