import { ErrorSearchListVector } from "assets/ErrorSearchListVector";

export const ErrorListInfo = () => (
  <div className="flex h-full flex-col items-center justify-center gap-8 rounded-2xl bg-jutro-new-warm-gray-50/50">
    <ErrorSearchListVector />

    <p className="font-paragraph-0 text-center">
      Nie udało się pobrać listy. Odśwież stronę, żeby spróbować ponownie
    </p>
  </div>
);
