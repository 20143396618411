import { motion } from "framer-motion";
import { PropsWithChildren, ReactNode } from "react";

type Props = {
  isScrolled?: boolean;
  rightActionElement?: ReactNode;
};

export const AnimatedFiltersWrapper = ({
  children,
  rightActionElement,
  isScrolled,
}: PropsWithChildren<Props>) => (
  <div className="flex justify-between gap-4">
    <motion.div
      transition={{ duration: 0.3 }}
      animate={{
        opacity: isScrolled ? 0 : 100,
        height: isScrolled ? 0 : "auto",
        visibility: isScrolled ? "hidden" : "visible",
      }}
    >
      {children}
    </motion.div>

    {isScrolled ? null : rightActionElement}
  </div>
);
