import { useMotionValueEvent, useScroll } from "framer-motion";
import { ReactNode, RefObject, useState } from "react";
import {
  SetContainerRef,
  SetIsScrolled,
} from "components/AnimatedHeaderOnScrollWrapper/types";

type Props = {
  isScrolled: boolean;
  setContainerRef: SetContainerRef;
  setIsScrolled: SetIsScrolled;
};

export const AnimatedHeaderOnScrollWrapper = ({
  children,
}: {
  children: (props: Props) => ReactNode;
}) => {
  const [containerRef, setContainerRef] = useState<
    RefObject<HTMLDivElement> | undefined
  >();

  const [isScrolled, setIsScrolled] = useState(false);

  const { scrollY } = useScroll({
    container: containerRef,
  });

  useMotionValueEvent(scrollY, "change", (latest) => {
    const container = containerRef?.current;

    if (!container) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = container;
    const isScrollAtBottom = scrollTop + clientHeight >= scrollHeight - 100;

    if (isScrollAtBottom) {
      return;
    }

    setIsScrolled(latest > 20);
  });

  return children({ isScrolled, setContainerRef, setIsScrolled });
};
