import { useAtom } from "jotai";
import { SetContainerRef } from "components/AnimatedHeaderOnScrollWrapper/types";
import { usePerDayVisitsQuery } from "lib/graphql/megaSchema";
import {
  selectedDateAtom,
  selectedDoctorAtom,
  showTodoOnlyAtom,
} from "views/Visits/PerDay/atoms";
import { VisitsTable } from "views/Visits/PerDay/components/VisitList/VisitTable";
import { useVisits } from "views/Visits/PerDay/components/VisitList/hooks/useVisits";
import { EmptyListInfo } from "views/Visits/components/EmptyListInfo";
import { ErrorListInfo } from "views/Visits/components/ErrorListInfo";

type Props = {
  setContainerRef: SetContainerRef;
};

export const VisitList = ({ setContainerRef }: Props) => {
  const [selectedDate] = useAtom(selectedDateAtom);
  const [selectedDoctor] = useAtom(selectedDoctorAtom);
  const [showTodoOnly] = useAtom(showTodoOnlyAtom);

  const {
    data,
    loading: loadingVisits,
    error: errorVisits,
  } = usePerDayVisitsQuery({
    variables: {
      doctorId: selectedDoctor?.id,
      forDay: selectedDate,
    },
    pollInterval: 30 * 1000,
    fetchPolicy: "no-cache",
  });

  const visits = useVisits(data?.doctorGetVisitsPerDayForDoctor, showTodoOnly);

  if (loadingVisits)
    return (
      <div className="w-full flex-1 animate-pulse rounded-2xl bg-jutro-new-warm-gray-50 duration-300" />
    );

  if (errorVisits) return <ErrorListInfo />;

  if (visits.length === 0)
    return <EmptyListInfo text="Brak zaplanowanych wizyt" />;

  return <VisitsTable visits={visits} setContainerRef={setContainerRef} />;
};
