import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { currentUserAtom } from "lib/atoms/auth";
import {
  selectedDateAtom,
  selectedDoctorAtom,
} from "views/Visits/PerDay/atoms";
import { getSelectedDoctorFromCurrentUser } from "views/Visits/PerDay/tools";

export const useResetControlsOnUnmount = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const [, setSelectedDate] = useAtom(selectedDateAtom);
  const [, setSelectedDoctor] = useAtom(selectedDoctorAtom);

  useEffect(() => {
    if (!currentUser) return;

    setSelectedDoctor(getSelectedDoctorFromCurrentUser(currentUser));
  }, [currentUser]);

  useEffect(() => {
    return () => {
      setSelectedDate(dayjs());

      if (!currentUser) return;

      setSelectedDoctor(getSelectedDoctorFromCurrentUser(currentUser));
    };
  }, []);
};
