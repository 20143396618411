import { Avatar } from "@jutro/ui";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { envVars } from "envvars";
import { tv } from "tailwind-variants";
import { DoctorMSPatient } from "lib/graphql/megaSchema";
import { formatPatientCardNumber } from "lib/tools/formatPatientCardNumber";
import { avatarConfig } from "lib/tools/httpConfigs";
import { Visit } from "views/Visits/PerDay/components/VisitList/types";
import {
  getIsVisitCompletedOrOutdated,
  getVisitTypeLabel,
} from "views/Visits/PerDay/tools";
import { AdditionalVisitInfo } from "./AdditionalVisitInfo";
import { VisitStatusTag } from "./VisitStatusTag";
import { VisitTypeTag } from "./VisitTypeTag";

const getStyles = tv({
  slots: {
    textWrapper: "font-label",
    avatarWrapper: "h-6 w-6",
  },
  variants: {
    isVisitCompletedOrOutdated: {
      true: {
        textWrapper: "text-jutro-new-warm-gray-500",
        avatarWrapper: "opacity-60",
      },
      false: {
        textWrapper: "text-jutro-new-warm-gray-800",
        avatarWrapper: "opacity-100",
      },
    },
  },
});

export const visitsPerDayColumns: ColumnDef<Visit>[] = [
  {
    accessorKey: "hour",
    header: () => <div className="-mx-5 w-0" />,
    cell: () => <div className="-mx-5 w-0" />,
  },
  {
    accessorKey: "plannedStart.iso",
    header: () => <div className="w-12">Czas</div>,
    cell: ({ getValue, row }) => {
      const { textWrapper } = getStyles({
        isVisitCompletedOrOutdated: getIsVisitCompletedOrOutdated(row.original),
      });

      return (
        <div className="w-12">
          <span className={textWrapper()}>
            {dayjs(getValue<string>()).format("H:mm")}
          </span>
        </div>
      );
    },
    aggregatedCell: ({ row }) => (
      <span className="font-label">{row.original.hour}:00</span>
    ),
  },
  {
    id: "patient",
    accessorKey: "patient",
    header: () => <div className="w-72">Imię i nazwisko</div>,
    cell: ({ getValue, row }) => {
      const { textWrapper, avatarWrapper } = getStyles({
        isVisitCompletedOrOutdated: getIsVisitCompletedOrOutdated(row.original),
      });

      const { id, firstName, lastName, avatar } = getValue<DoctorMSPatient>();

      const getAvatarProps = () => {
        const defaultAvatars = ["patient-avatar.png", "female-avatar.png"];

        if (defaultAvatars.includes(avatar)) {
          return {
            initials: `${firstName?.[0]}${lastName?.[0]}`,
          };
        }

        return {
          imgSrc: `${avatarConfig[envVars.REACT_APP_CONFIG]}/${id}`,
        };
      };

      return (
        <div className="flex w-72 gap-3">
          <div className={avatarWrapper()}>
            <Avatar {...getAvatarProps()} />
          </div>
          <span className={textWrapper()}>
            {firstName} {lastName}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "patient.patientCardNumber",
    header: () => <div className="w-28">Numer karty</div>,
    cell: ({ getValue, row }) => {
      const { textWrapper } = getStyles({
        isVisitCompletedOrOutdated: getIsVisitCompletedOrOutdated(row.original),
      });

      return (
        <div className="flex h-6 w-28 items-center">
          <p className={textWrapper()}>
            {formatPatientCardNumber(getValue<string>())}
          </p>
        </div>
      );
    },
  },
  {
    id: "visitType",
    header: () => <div className="w-24">Typ wizyty</div>,
    cell: ({ row }) => {
      return (
        <div className="flex h-6 w-24 items-center">
          <VisitTypeTag visit={row.original} />
        </div>
      );
    },
  },
  {
    id: "visitStatus",
    header: () => <div className="w-28">Status</div>,
    cell: ({ row }) => (
      <div className="flex h-6 w-28 items-center">
        <VisitStatusTag visitElement={row.original} />
      </div>
    ),
  },
  {
    id: "visitTypeLabel",
    header: () => <div className="w-64">Rodzaj wizyty</div>,
    cell: ({ row }) => {
      const { textWrapper } = getStyles({
        isVisitCompletedOrOutdated: getIsVisitCompletedOrOutdated(row.original),
      });

      return (
        <div className="flex h-6 w-64 items-center">
          <span className={textWrapper()}>
            {getVisitTypeLabel(
              row.original.procedures,
              row.original.laboratoryExaminations,
              row.original.type,
            )}
          </span>
        </div>
      );
    },
  },
  {
    id: "additional",
    header: () => <div className="w-64">Dodatkowe</div>,
    cell: ({ row }) => {
      const { textWrapper } = getStyles({
        isVisitCompletedOrOutdated: getIsVisitCompletedOrOutdated(row.original),
      });

      return (
        <div className="max-w-64">
          <span className={textWrapper()}>
            <AdditionalVisitInfo visit={row.original} />
          </span>
        </div>
      );
    },
  },
];
