import { useAtom } from "jotai";
import { Fragment, useEffect } from "react";
import { AnimatedHeaderOnScrollWrapper } from "components/AnimatedHeaderOnScrollWrapper";
import { AnimatedFiltersWrapper } from "components/AnimatedHeaderOnScrollWrapper/AnimatedFiltersWrapper";
import { useDoctorArchivedVisitListQuery } from "lib/graphql/megaSchema";
import {
  visitsArchiveCurrentPageAtom,
  visitsArchiveDateAtom,
  visitsArchiveFiltersAtom,
  visitsArchiveSearchAtom,
  visitsArchiveTotalPagesAtom,
  visitsArchiveVojevodshipIdAtom,
} from "views/Visits/Archive/atoms";
import { VisitsArchiveFilters } from "views/Visits/Archive/components/VisitsArchiveFilters";
import { VisitsArchiveTable } from "views/Visits/Archive/components/VisitsArchiveTable";
import { buildVisitsArchiveFiltersInput } from "views/Visits/Archive/tools";
import { VisitsTabs } from "views/Visits/components/VisitsTabs";

export const VisitsArchiveTab = () => {
  const [vojevodshipId] = useAtom(visitsArchiveVojevodshipIdAtom);
  const [startDate] = useAtom(visitsArchiveDateAtom);
  const [filters] = useAtom(visitsArchiveFiltersAtom);
  const [search] = useAtom(visitsArchiveSearchAtom);

  const [totalPages, setTotalPages] = useAtom(visitsArchiveTotalPagesAtom);
  const [currentPage] = useAtom(visitsArchiveCurrentPageAtom);

  const { data, loading: isDataLoading } = useDoctorArchivedVisitListQuery({
    fetchPolicy: "network-only",
    variables: {
      pageSize: 50,
      page: currentPage,
      filters: {
        organizationId: vojevodshipId,
        startDate: startDate.toISOString(),
        endDate: startDate.endOf("month").toISOString(),
        patientSearch: search,
        ...buildVisitsArchiveFiltersInput(filters),
      },
    },
  });

  useEffect(() => {
    if (
      data?.doctorArchivedVisitList.totalPages === undefined ||
      data.doctorArchivedVisitList.totalPages === totalPages
    ) {
      return;
    }

    setTotalPages(data.doctorArchivedVisitList.totalPages);
  }, [data]);

  return (
    <AnimatedHeaderOnScrollWrapper>
      {({ isScrolled, setIsScrolled, setContainerRef }) => (
        <Fragment>
          <div className="flex items-center justify-between gap-4">
            <div
              className={`flex flex-row gap-2.5 ${isScrolled ? "pb-0" : "pb-4"}`}
            >
              <h1 className="font-heading-1 pl-1 text-jutro-new-warm-gray-800">
                Wizyty
              </h1>

              <VisitsTabs
                isScrolled={isScrolled}
                setIsScrolled={setIsScrolled}
              />
            </div>
          </div>

          <div className="flex h-full min-h-0 flex-col gap-3">
            <AnimatedFiltersWrapper isScrolled={isScrolled}>
              <VisitsArchiveFilters />
            </AnimatedFiltersWrapper>

            {isDataLoading ? (
              <div className="w-full flex-1 animate-pulse rounded-2xl bg-jutro-new-warm-gray-50 duration-300" />
            ) : (
              <VisitsArchiveTable
                setContainerRef={setContainerRef}
                data={data?.doctorArchivedVisitList.visits ?? []}
              />
            )}
          </div>
        </Fragment>
      )}
    </AnimatedHeaderOnScrollWrapper>
  );
};
