import { Tag } from "@jutro/ui";
import { VisitsItem } from "views/Visits/PerDay/components/VisitList/types";

type Props = {
  visit: VisitsItem;
};

export const VisitTypeTag = ({ visit }: Props) => {
  if (visit.type === "VIRTUAL" && !visit.prescriptionRequestId) {
    return <Tag text="Telemedyczna" color="green" />;
  }

  if (visit.type === "VIRTUAL" && visit.prescriptionRequestId) {
    return <Tag text="Receptowa" color="orange" />;
  }

  if (visit.type === "STANDARD") {
    return <Tag text="Osobista" color="blue" />;
  }

  return <Tag text="Zabiegowa" color="rose" />;
};
