import { Select } from "@jutro/ui";
import { useAtom } from "jotai";
import { ScreenLoader } from "components/new";
import { DoctorMSDoctor } from "lib/graphql/megaSchema";
import { selectedDoctorAtom } from "views/Visits/PerDay/atoms";
import { getDoctorOption } from "views/Visits/PerDay/components/Controls/DoctorSelect/tools";
import { useGetDoctorOptions } from "./hooks";

type Props = {
  disabled?: boolean;
};

export const DoctorSelect = ({ disabled }: Props) => {
  const [selectedDoctor, setSelectedDoctor] = useAtom(selectedDoctorAtom);

  const doctorsOptions = useGetDoctorOptions();

  if (!selectedDoctor || doctorsOptions.length === 0) return <ScreenLoader />;

  return (
    <Select
      placeholder="Kliknij, aby wyszukać..."
      noOptionsMessage="Brak wyników."
      isSearchable
      disabled={disabled}
      options={doctorsOptions}
      onChange={(e) => {
        if (!e) return;
        setSelectedDoctor(e.value);
      }}
      value={getDoctorOption(selectedDoctor as DoctorMSDoctor)}
    />
  );
};
