import { Pagination, Table } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SetContainerRef } from "components/AnimatedHeaderOnScrollWrapper/types";
import {
  visitsArchiveCurrentPageAtom,
  visitsArchiveFiltersAtom,
  visitsArchiveSearchAtom,
  visitsArchiveTotalPagesAtom,
} from "views/Visits/Archive/atoms";
import { visitsArchiveTableColumns } from "views/Visits/Archive/components/VisitsArchiveTable/columns";
import { ArchivedVisit } from "views/Visits/Archive/types";
import { EmptyListInfo } from "views/Visits/components/EmptyListInfo";

type Props = {
  data: ArchivedVisit[];
  setContainerRef: SetContainerRef;
};

export const VisitsArchiveTable = ({ data, setContainerRef }: Props) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const [totalPages] = useAtom(visitsArchiveTotalPagesAtom);
  const [currentPage, setCurrentPage] = useAtom(visitsArchiveCurrentPageAtom);
  const [filters] = useAtom(visitsArchiveFiltersAtom);
  const [search] = useAtom(visitsArchiveSearchAtom);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    setContainerRef(ref);
  }, [ref]);

  if (data.length === 0) {
    return (
      <EmptyListInfo
        text={
          filters.length > 0 || search
            ? "Ups, brak wizyt spełniających kryteria"
            : "Brak wizyt do wyświetlenia"
        }
      />
    );
  }

  return (
    <div className="flex h-full min-h-0 flex-col gap-3">
      <Table
        ref={ref}
        columns={visitsArchiveTableColumns}
        data={data}
        selectionRowMode="single"
        selectedRow={null}
        setSelectedRow={(_visit) => {
          const visit = _visit as ArchivedVisit;

          if (!visit) {
            return;
          }

          navigate(`/visits/${visit.id}`);
        }}
      />

      {totalPages ? (
        <div className="flex items-center justify-center">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </div>
  );
};
