import { EmptySearchListVector } from "assets/EmptySearchListVector";

type Props = {
  text: string;
};

export const EmptyListInfo = ({ text }: Props) => (
  <div className="flex h-full flex-col items-center justify-center gap-8 rounded-2xl bg-jutro-new-warm-gray-50/50">
    <EmptySearchListVector />

    <p className="font-paragraph-0 text-center">{text}</p>
  </div>
);
