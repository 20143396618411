import { Button } from "@jutro/ui";
import { omit } from "ramda";
import { useState } from "react";
import ReactModal from "react-modal";
import {
  DoctorTemplateParamsFragment,
  useDoctorPublishTestingExaminationResultsMutation,
} from "lib/graphql/megaSchema";
import { toaster } from "lib/tools/toaster";
import { LabExamParameter } from "./LabExamParameter";
import { TemplateModal } from "./TemplateModal";

type Props = {
  name: string;
  serviceId: string;
  visitId: string | undefined;
  initialParams: DoctorTemplateParamsFragment[];
  deleteFromList: (serviceId: string) => void;
};

const modalOverlayStyles = {
  overlay: {
    zIndex: 50,
    backgroundColor: "rgba(0,0,0, .6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const LabExamParameterGroup = ({
  name,
  initialParams,
  serviceId,
  visitId,
  deleteFromList,
}: Props) => {
  const [currentParams, setCurrentParams] =
    useState<DoctorTemplateParamsFragment[]>(initialParams);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  const [publishTestingExamResults] =
    useDoctorPublishTestingExaminationResultsMutation();

  const handleChange = (newValue: string, index: number) => {
    const newCurrentParams = [...currentParams];
    newCurrentParams[index] = { ...newCurrentParams[index], value: newValue };
    setCurrentParams(newCurrentParams);
  };

  const publishResults = async () => {
    if (!visitId) {
      return;
    }
    const params = currentParams.map((param) => {
      return omit(["__typename"], { ...param, icd9: "" });
    });

    await publishTestingExamResults({
      variables: {
        labExam: { params: params, serviceId: serviceId },
        visitId: visitId,
      },
    });
  };

  const handlePublishResults = async () => {
    if (currentParams.length === 0) {
      toaster.error("Nie można opublikowac badania bez parametrów");
      return;
    }

    deleteFromList(serviceId);

    await toaster.promise(publishResults(), {
      error: "Nie udało się opublikować wyników badania",
      loading: "Publikuje wyniki badania",
      success: "opublikowano wyniki badania",
    });
  };

  const handleParamsSave = (editedParams: DoctorTemplateParamsFragment[]) => {
    setCurrentParams(editedParams);
  };

  return (
    <div className="w-full rounded-lg border-[1px] border-black p-4 shadow-lg">
      <h3 className="font-heading-3">{name}</h3>
      <ul className="flex w-full flex-wrap justify-start gap-5">
        {currentParams.map((param, index) => (
          <LabExamParameter
            key={param.name}
            param={param}
            onValueChange={(newValue) => handleChange(newValue, index)}
          />
        ))}
        <div className="mt-5 flex w-full justify-center gap-10">
          <Button text="Opublikuj" onClick={handlePublishResults} />
          <Button
            text="Edytuj listę parametrów"
            variant="secondary"
            onClick={() => setIsTemplateModalOpen(true)}
          />
          <Button
            text="Usuń"
            variant="negative"
            onClick={() => deleteFromList(serviceId)}
          />
        </div>
      </ul>

      <ReactModal
        style={modalOverlayStyles}
        isOpen={isTemplateModalOpen}
        ariaHideApp={false}
        contentElement={() => (
          <TemplateModal
            onParamsSave={handleParamsSave}
            initialParams={currentParams}
            onModalClose={() => setIsTemplateModalOpen(false)}
          />
        )}
      />
    </div>
  );
};
