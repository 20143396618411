import { FieldError } from "react-hook-form";
import { tv } from "tailwind-variants";

type Props = {
  error?: FieldError;
  pickedGender: string | undefined;
  disabled?: boolean;
  onChange?: (...event: string[]) => void;
};

const getStyles = tv({
  base: "font-paragraph-1 bg-white flex flex-1 items-center justify-center rounded-lg border-[1px] px-4 py-2 text-jutro-new-warm-gray-800 border-jutro-new-warm-gray-300 cursor-pointer hover:border-jutro-new-blue-800",
  variants: {
    error: {
      true: "border-jutro-new-rose-600",
    },
    selected: {
      true: "bg-jutro-new-blue-800 text-white",
    },
    disabled: {
      true: "cursor-not-allowed hover:border-jutro-new-warm-gray-300",
    },
  },
  compoundVariants: [
    {
      selected: true,
      disabled: true,
      class: "bg-jutro-new-warm-gray-100 text-jutro-new-warm-gray-800",
    },
    {
      selected: false,
      disabled: true,
      class: "hidden",
    },
  ],
});

export const GenderSelect = ({
  error,
  pickedGender,
  disabled,
  onChange,
}: Props) => {
  const genderArray = ["Żeńska", "Męska"];

  return (
    <ul className="flex gap-2">
      {genderArray.map((gender) => (
        <li
          key={gender}
          className={getStyles({
            error: Boolean(error),
            disabled,
            selected: gender === pickedGender,
          })}
          onClick={() => {
            if (disabled || !onChange) return;
            onChange(gender);
          }}
        >
          {gender}
        </li>
      ))}
    </ul>
  );
};
