export const ErrorSearchListVector = () => (
  <svg
    width="80"
    height="76"
    viewBox="0 0 80 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.2999 55.4232C75.9212 53.458 74.0378 51.9125 72.181 50.388L15.6701 12.1559C13.5422 10.4127 11.3128 8.61577 8.64209 7.9562C5.9714 7.29662 2.70824 8.12893 1.44415 10.5741C0.483283 12.4323 0.89158 14.7618 1.9573 16.5613C3.02302 18.3608 4.65986 19.7405 6.26259 21.0837L64.4986 61.1232C67.0043 63.2249 72.2392 67.6526 75.9392 66.2891C80.4251 64.6309 79.4172 58.4385 77.2999 55.4232Z"
      fill="#FCE1DE"
    />
    <path
      d="M64.1303 1.71444C62.0852 2.97161 60.4285 4.75796 58.7944 6.5191L11.7579 60.9494C9.88897 62.9678 7.96038 65.0844 7.1403 67.7102C6.32023 70.3361 6.95342 73.6437 9.31757 75.0535C11.1142 76.1251 13.4641 75.8586 15.3249 74.9038C17.1856 73.949 18.6618 72.3987 20.0997 70.8802L69.0448 14.8373C71.2944 12.4635 76.0309 7.50634 74.8939 3.73061C73.5104 -0.847481 67.2683 -0.216414 64.1303 1.71444Z"
      fill="#FCE1DE"
    />
  </svg>
);
