import { Table } from "@jutro/ui";
import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SetContainerRef } from "components/AnimatedHeaderOnScrollWrapper/types";
import { currentUserAtom } from "lib/atoms/auth";
import { isTestUserPhone } from "lib/tools/auth";
import { visitsPerDayColumns } from "views/Visits/PerDay/components/VisitList/VisitTable/columns";
import { Visit } from "views/Visits/PerDay/components/VisitList/types";

type Props = {
  visits: Visit[];
  setContainerRef: SetContainerRef;
};

export const VisitsTable = ({ visits, setContainerRef }: Props) => {
  const navigate = useNavigate();
  const [currentUser] = useAtom(currentUserAtom);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setContainerRef(ref);
  }, [ref.current, visits]);

  return (
    <div className="overflow-y-auto">
      <Table
        ref={ref}
        data={visits}
        columns={visitsPerDayColumns}
        groupBy="hour"
        selectionRowMode="single"
        selectedRow={null}
        setSelectedRow={(row) => {
          const visit = row as Visit | null;

          if (!visit) {
            return;
          }

          if (
            visit.doctor?.id !== currentUser?.id &&
            !isTestUserPhone(currentUser?.phone)
          ) {
            return;
          }

          navigate(`/visits/${visit.id}`);
        }}
      />
    </div>
  );
};
