import { Fragment } from "react/jsx-runtime";
import { Controls } from "./components/Controls";
import { VisitList } from "./components/VisitList";
import { AnimatedHeaderOnScrollWrapper } from "components/AnimatedHeaderOnScrollWrapper";
import { AnimatedFiltersWrapper } from "components/AnimatedHeaderOnScrollWrapper/AnimatedFiltersWrapper";
import { VisitsTabs } from "views/Visits/components/VisitsTabs";

export const VisitsPerDayTab = () => (
  <AnimatedHeaderOnScrollWrapper>
    {({ isScrolled, setIsScrolled, setContainerRef }) => (
      <Fragment>
        <div className="flex items-center justify-between gap-4">
          <div
            className={`flex flex-row gap-2.5 ${isScrolled ? "pb-0" : "pb-4"}`}
          >
            <h1 className="font-heading-1 pl-1 text-jutro-new-warm-gray-800">
              Wizyty
            </h1>

            <VisitsTabs isScrolled={isScrolled} setIsScrolled={setIsScrolled} />
          </div>
        </div>

        <div className="flex h-full min-h-0 flex-col gap-3">
          <AnimatedFiltersWrapper isScrolled={isScrolled}>
            <Controls />
          </AnimatedFiltersWrapper>

          <VisitList setContainerRef={setContainerRef} />
        </div>
      </Fragment>
    )}
  </AnimatedHeaderOnScrollWrapper>
);
