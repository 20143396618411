export const EmptySearchListVector = () => (
  <svg
    width="152"
    height="65"
    viewBox="0 0 152 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.30069 12.4665C-3.17841 22.9294 -2.64881 43.1981 8.19177 54.5479C20.2736 67.1953 40.7466 67.9016 55.2283 59.1634C62.6863 54.5347 68.8324 48.096 73.0863 40.4551C77.737 32.2425 81.3616 21.5497 77.6874 12.3188C74.4766 4.33611 65.9696 -1.85625 57.082 0.968885C49.0715 3.49837 43.9575 11.7767 42.7658 19.6937C40.3494 35.9546 50.4949 52.3799 65.2579 59.262C80.8154 66.4891 98.69 61.6601 109.944 49.1605C115.435 43.192 119.072 35.7777 120.421 27.8077C121.646 19.8743 120.52 11.0376 114.893 4.81243C108.571 -2.11901 98.6734 -1.06779 92.0697 4.81243C85.4661 10.6927 82.9008 19.7265 83.3807 28.1362C83.8549 36.6268 87.3777 44.668 93.311 50.803C99.5471 56.9289 107.908 60.455 116.68 60.6581C125.432 60.9975 133.977 57.9666 140.53 52.1991C146.809 46.4272 150.797 38.6126 151.767 30.173C152.247 26.1407 151.983 22.0554 150.99 18.1168C149.848 13.5013 142.714 15.4559 143.807 20.0879C146.935 32.6696 139.934 47.2059 127.405 51.6243C121.072 53.9614 114.09 53.8751 107.818 51.3822C101.546 48.8893 96.4331 44.1683 93.4765 38.1391C90.7575 32.3108 90.1721 25.7237 91.8215 19.513C93.2779 14.0927 97.879 6.767 104.45 7.24333C110.292 7.68681 112.99 14.4541 113.503 19.4309C114.016 25.861 112.501 32.2902 109.166 37.8271C102.844 48.7334 90.4478 56.6833 77.4722 55.1557C64.8276 53.661 53.8712 43.2145 50.7597 31.1584C49.909 28.1769 49.6743 25.0556 50.0696 21.9818C50.4649 18.908 51.4822 15.9453 53.0602 13.2714C56.1883 8.34389 62.1299 5.53509 67.1778 9.69067C73.3511 14.7661 72.325 23.4058 69.8755 30.0744C67.4221 36.7317 63.4581 42.7396 58.2902 47.633C48.4923 56.8639 33.7292 60.7731 21.1839 54.6301C18.3732 53.3479 15.8457 51.5268 13.7467 49.2718C11.6477 47.0167 10.0186 44.3721 8.95309 41.4899C6.97883 35.9144 7.18558 29.8063 9.53236 24.3748C10.7442 21.7504 12.5376 19.4314 14.7789 17.5912C15.4693 16.8913 15.8559 15.9509 15.8559 14.9714C15.8559 13.992 15.4693 13.0514 14.7789 12.3515C14.0705 11.6789 13.1278 11.3035 12.1474 11.3035C11.1669 11.3035 10.2243 11.6789 9.51585 12.3515L9.30069 12.4665Z"
      fill="#DCEFF5"
    />
  </svg>
);
