import { QuestionIcon, Tooltip } from "@jutro/ui";

type labelSize = "xs" | "sm";

type TooltipPosition = "bottom" | "left" | "right" | "top";

const sizesMap: Record<labelSize, string> = {
  xs: "font-paragraph-2",
  sm: "font-label",
};

type SelectWrapperProps = {
  children: React.ReactNode | React.ReactNode[];
  label: string;
  labelSize?: labelSize;
  withTooltip?: boolean;
  tooltipContent?: string;
  tooltipPosition?: TooltipPosition;
};

export const SelectWrapper = ({
  children,
  label,
  labelSize = "sm",
  withTooltip = false,
  tooltipContent,
  tooltipPosition = "bottom",
}: SelectWrapperProps) => {
  return (
    <div className="flex grow flex-col gap-1">
      <div className="flex flex-1 items-center gap-2">
        <span className={`${sizesMap[labelSize]} text-jutro-new-warm-gray-800`}>
          {label}
        </span>
        {withTooltip && (
          <Tooltip
            position={tooltipPosition}
            content={
              <div className="font-paragraph-2 max-w-[300px] px-4 py-2">
                {tooltipContent}
              </div>
            }
            trigger={
              <div className="flex cursor-pointer items-center justify-center">
                <QuestionIcon size="sm" />
              </div>
            }
          />
        )}
      </div>
      <>{children}</>
    </div>
  );
};
