import { Button, MonthPicker, Toggle } from "@jutro/ui";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { Loader, ScreenLoader } from "components/new";
import { currentUserAtom } from "lib/atoms/auth";
import {
  selectedDateAtom,
  selectedDoctorAtom,
  showTodoOnlyAtom,
} from "views/Visits/PerDay/atoms";
import { getSelectedDoctorFromCurrentUser } from "views/Visits/PerDay/tools";
import { DoctorSelect } from "./DoctorSelect";
import { useResetControlsOnUnmount } from "./hooks";

export const Controls = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);
  const [selectedDoctor, setSelectedDoctor] = useAtom(selectedDoctorAtom);
  const [showTodoOnly, setShowTodoOnly] = useAtom(showTodoOnlyAtom);

  useResetControlsOnUnmount();

  if (!currentUser) return <ScreenLoader />;

  if (!selectedDoctor) return <Loader />;

  return (
    <div className="flex gap-10">
      <div className="flex gap-2">
        <Button
          full={false}
          variant="ghost"
          disabled={dayjs(selectedDate).isToday()}
          onClick={() => {
            if (dayjs(selectedDate).isToday()) return;
            setSelectedDate(dayjs());
          }}
          text="Dzisiaj"
        />

        <MonthPicker
          value={selectedDate.toDate()}
          format="D MMMM YYYY"
          maxDetail="month"
          onChange={(date) => {
            const stringDate = date?.toString();
            setSelectedDate(dayjs(stringDate).startOf("day"));
          }}
          onLeftArrowClick={() => {
            setSelectedDate((prev) => prev.subtract(1, "day"));
          }}
          onRightArrowClick={() => {
            setSelectedDate((prev) => prev.add(1, "day"));
          }}
        />
      </div>

      <div className="flex gap-2">
        <div className="w-80">
          <DoctorSelect disabled={currentUser.genre === "DOCTOR"} />
        </div>

        {currentUser.genre === "NURSE" && (
          <Button
            full={false}
            variant="secondary"
            disabled={selectedDoctor.id === currentUser.id}
            onClick={() => {
              if (selectedDoctor.id === currentUser.id) return;
              setSelectedDoctor(getSelectedDoctorFromCurrentUser(currentUser));
            }}
            text="Moi pacjenci"
          />
        )}
      </div>

      <Toggle
        isEnabled={showTodoOnly}
        setIsEnabled={setShowTodoOnly}
        label="Ukryj zakończone"
      />
    </div>
  );
};
